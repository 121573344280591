<template>
  <div>
    <van-tabbar v-model="activeMenu" @change="onMenuClick" route>
      <van-tabbar-item replace to="/home" icon="wap-home">首页</van-tabbar-item>
      <van-tabbar-item replace to="/product" icon="bag">产品</van-tabbar-item>
      <van-tabbar-item replace to="/message" icon="chat">消息</van-tabbar-item>
      <van-tabbar-item replace to="/mine" icon="manager">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { onBeforeMount, onMounted, ref } from "vue";
export default {
  setup() {
    const activeMenu = ref(0);
    const onMenuClick = (index) => {
      activeMenu.value = index;
    };
    onBeforeMount(() => {
      console.log(activeMenu.value);
    });
    onMounted(() => {
      console.log(activeMenu.value);
    });
    return { activeMenu, onMenuClick };
  },
};
</script>