<template>
    <div class="mine_index">
        <div class="user" v-if="userInfo">
            <div class="avatar">
                <image class="userinfo-avatar" :src="userInfo.wechat_cover" mode="cover" v-if="userInfo"></image>
            </div>
            <div class="name">
                <div class="wechat_name">
                    {{ userInfo.wechat_name }}
                </div>
                <div class="level">
                    <van-icon name="idcard" size="20" style="margin-right:15rpx;" />
                    <van-icon name="vip-card-o" size="20" />
                </div>
            </div>
            <div class="bill">
                <van-icon name="paid" size="32" color="#FFFFFF" @click="toWallet" />
            </div>
        </div>
        <div class="no_user" v-if="!userInfo">
            <van-button block type="default" class="login_btn" bindtap="toLoginPage"> 登录/注册 </van-button>
        </div>
        <div class="nav">
            <van-cell title="我的订单" icon="balance-list-o" is-link :border="false" class="nav_cell" title-class="nav_title"
                @click="toMyOrder" />
        </div>
        <div class="nav">
            <van-cell title="我的奶卡" icon="notes-o" is-link :border="false" class="nav_cell" title-class="nav_title"
                @click="toCycle" />
        </div>
        <div class="nav">
            <van-cell title="我的钱包" icon="balance-pay" is-link class="nav_cell" title-class="nav_title" @click="toWallet" />
            <van-cell title="我要充值" icon="cash-back-record" is-link class="nav_cell" title-class="nav_title"
                @click="toRechargePage" />
            <van-cell title="我的优惠券" icon="coupon-o" is-link class="nav_cell" title-class="nav_title" @click="toMyCoupon" />
            <van-cell title="邀请奖励" icon="friends-o" is-link class="nav_cell" title-class="nav_title" @click="toInvite"
                v-if="isInviteShow == true" />
            <van-cell title="联系客服" icon="service-o" is-link :border="false" class="nav_cell" title-class="nav_title"
                bindtap="toContactPage" />
            <van-cell title="退出登录" icon="close" is-link :border="false" class="nav_cell" title-class="nav_title"
                bindtap="toLoginOut" />
        </div>
    </div>
    <global-menu></global-menu>
</template>
<script>
import GlobalMenu from "../../components/GlobalMenu.vue";
import { ref, onBeforeMount } from 'vue';
import { useRouter } from "vue-router";
export default {
    components: {
        GlobalMenu,
    },
    setup() {
        const userInfo = ref('');
        onBeforeMount(() => {
            var userInfoData = localStorage.getItem('userInfo')
            if (userInfoData) {
                userInfo.value = JSON.parse(userInfoData)
            }
        });
        const router = useRouter();
        const toLoginPage = () => {
            router.push({ name: "Login" });
        };
        const toMyOrder = () => {
            router.push({ name: "Order" });
        };
        const toWallet = () => {
            router.push({ name: "Wallet" });
        };
        const toRechargePage = ()=>{
            router.push({name:'Recharge'})
        };
        const toMyCoupon = ()=>{
            router.push({name:'MineCoupon'})
        };
        const toContactPage = () => {
            router.push({ name: 'Contact' })
        };
        const toLoginOut = ()=>{
            localStorage.setItem('userInfo','')
        };
        return {
            userInfo,
            toLoginPage,
            toMyOrder,
            toWallet,
            toRechargePage,
            toMyCoupon,
            toContactPage,
            toLoginOut,
        }
    },
}
</script>
<style>
.mine_index .user {
    background-color: #07C160;
    padding: 15px 10px;
    display: flex;
}

.mine_index .user .avatar {
    border-radius: 100%;
    margin-bottom: 10px;
    width: 60px;
    height: 60rpx;
    text-align: center;
    overflow: hidden;
    margin-right: 10px;
}

.mine_index .userinfo-avatar {
    overflow: hidden;
    width: 60px;
    height: 600rpx;
    border-radius: 50%;
}

.mine_index .user .name {
    font-size: 18px;
    color: #ffffff;
    flex: 1;
}

.mine_index .user .name .wechat_name {
    margin-top: 0px;
    margin-bottom: 10px;
}

.mine_index .user .bill {
    padding-top: 10px;
}

.mine_index .no_user {
    background-color: #07C160;
    padding: 30px 10px;
    display: flex;
    text-align: center;
}

.mine_index .no_user .login_btn {
    background-color: #ffffff;
    color: #07C160;
    font-size: 18px;
}


.mine_index .nav {
    border-top: 10px solid #ededed;
}

.mine_index .nav .nav_cell {
    padding: 15px 10px;
}

.mine_index .nav .nav_title {
    font-size: 18px;
}

.mine_index .nav .nav_cell .van-cell__left-icon {
    color: #07C160;
    font-size: 24px;
    margin-right: 10px;
}</style>